import { dayjsExtended } from "./utils";

export const ENDPOINT_LOCAL = "http://andre-mac.local:3001";
export const ENDPOINT = "https://3v3ux22mac.execute-api.us-west-2.amazonaws.com/Prod/";
export const SITE_BANNER = "Mater Angel Tree 2024";
const SCHOOL_NAME = "Mater Academy Bonanza Campus";
const SCHOOL_ADDRESS = "4760 E. Bonanza Rd. Las Vegas, NV 89110";
export const DEADLINE = "2024-12-4 15:00";
export const TIMEZONE = "America/Los_Angeles";
export const donationDeadline = dayjsExtended.tz(DEADLINE, TIMEZONE).format("MMM Do hA");
export const SUPPORT_EMAIL = "armelita.lawrence@materlv.org";
export const INSTRUCTIONS = `Thank you for adopting a student this year for our Angel Tree.

First click on an ornament. Please fill out required information and when you submit, you will receive a confirmation email with a donation ID#.

Then wrap your gifts, write the donation ID# on a gift tag, attach to your gifts, and return to ${SCHOOL_NAME} located at ${SCHOOL_ADDRESS} as soon as possible or by the deadline ${donationDeadline}.
`;
export const INSTRUCTIONS_ESP = `¡Felices fiestas! Gracias por adoptar a un estudiante este año para nuestro árbol de regalos.

Primero, haga clic en un adorno. Complete la información requerida y, cuando la envíe, recibirá un correo electrónico de confirmación con un número de identificación de donación. 

Luego, envuelva sus regalos, escriba el número de identificación de donación en una etiqueta de regalo, colóquela en sus regalos y devuélvala al campus de ${SCHOOL_ADDRESS} lo antes posible o antes de la fecha límite del 4 de diciembre de 2024 a las 3:00 p.m.
`;

export const DONATION_PRINTABLE = `Please make sure to drop off the donations at ${SCHOOL_NAME}’s front office no later than:

<strong>${donationDeadline}</strong>

<strong>Drop-off location:</strong>
${SCHOOL_ADDRESS}

Thank you for your giving hearts and participation.
Wishing you a <strong>Merry Christmas and Happy Holidays!</strong>
`;

export const SEPARATE_TOYS_APPAREL = false;
export const SCHOOL_OPTIONS = ["Mater Bonanza"];
export const TEACHER_GUARDIAN_INFO = false;
